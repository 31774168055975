import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggProvider } from '@frontegg/react';

//For Test
const contextOptions = {
  baseUrl: "https://app-bjh4d9jeoojw.us.frontegg.com",
  clientId: '0debb658-97da-43bf-b5ca-5e6114296593'
};

// Ajout de themeOptions pour le mode plein écran
const themeOptions = {
  adminPortal: {
    layout: {
      fullScreenMode: true
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FronteggProvider contextOptions={contextOptions} themeOptions={themeOptions} hostedLoginBox={true}>
    <App />
  </FronteggProvider>
);

reportWebVitals();
